/**
* myInfo sagas
*/
import { all, call, put, takeLatest, select  } from 'redux-saga/effects'
import { my_info_exists_actions, load_my_info_actions, get_referenced_user_actions, set_current_user_actions, current_user_changed_actions, add_to_my_users_actions, remove_from_my_users_actions } from '../actions/myInfoActions'
import { push } from 'connected-react-router';
import * as actionTypes from '../actions/actionTypes'
import myInfoApi from  '../api/myInfoApi'
import infoApi from  '../api/infoApi'
import authApi from  '../api/authApi'


const fetchMyInfo = () =>  myInfoApi.getMyInfo();
const fetchReferencedUser = (hash) => infoApi.getHousingReference(hash)
// const getUserReferenceHashId = (state) => state.myinfo.user_reference.hash_id;
const getUserReference = (state) => state.myinfo.user_reference;
const getIsAuthenticated = (state) => state.auth.isAuthenticated;
const addIndividualUser = (data) => authApi.addIndividualUser(data);
const removeIndividualUser = (data) => authApi.removeIndividualUser(data);
const getCurrentUserID = (state) => state.myinfo.currentUser.id;
const getMyUsers = (state) => state.myinfo.myinfo.users;
const isMyInfoLoaded = (state) => state.myinfo.myinfo.id ? true : false;

function* getMyInfo() {
  try {
    yield put(load_my_info_actions.pending())
    const myInfo = yield call(fetchMyInfo);
    yield put(load_my_info_actions.success(myInfo))
  } catch (e) {
    console.log(e)
    yield put(load_my_info_actions.error(e))
  }
}

// Haetaan myinfot, jos statessa eijo.
// Näin käy, jos esim palaa softaan = state on orggis, mutta tokeni ehkä
// local storagessa ja sieltä saadaan sessio takas päälle = tarvitaan
// omat tiedot ja muut.
// Omia tietoja ei kuitenkaan tarvita, jos ne on jo asetettu.
// Omat tiedot hakemalla voidaan kuitenkin tarkistaa, että token on voimissaan.
function* checkMyInfoLoaded() {

      yield put(my_info_exists_actions.pending());
      
      const myInfoLoaded = yield select(isMyInfoLoaded);
      
      if (myInfoLoaded)
        yield put(my_info_exists_actions.success())
      else
        yield put(my_info_exists_actions.error())

}

function* getReferencedUser() {

    try {
      const userReference = yield select(getUserReference);

      const referencedUser = yield call(fetchReferencedUser, userReference.hash_id);

      yield put(get_referenced_user_actions.success(referencedUser));

      // Mitäs sitte? tänne tullaan käytännössä ko sivulle ländätään urlilla
      // /oma/235sdgdg
      // Jos koodilla löytyi taloyhtiö, se on nyt asetettu stateen viitteeksi.
      // Pitäis seuraavaksi tsekata onks se oma? Jos on, asetetaan se currentUseriksi.
      const isAuthenticated = yield select (getIsAuthenticated);
      const myUsers = yield select (getMyUsers);

      if (isAuthenticated && myUsers.find(myUser => myUser.id === referencedUser.id)) {
        // Huom, ohjaa automagic etusivulle
        yield put( set_current_user_actions.pending( referencedUser.id ) );

      // Jos on sisäänkirjautuneena, lisätään omiin
      } else if (isAuthenticated) {
        yield call (addToMyUsers);
console.log("jiihaa, referencedi meni omiin. asetetaan nykykäyttäjäks.", referencedUser);
        // Ja otetaa käyttöö
        // Huom, ohjaa automagic etusivulle
        yield put( set_current_user_actions.pending( referencedUser.id ) );       

      // jos eijo sisäänkirjautuneena, ohjataan rekisteröitymiseen
      } else {
        yield put ( push('/auth/register') );
      }
    } catch (e) {
      let error_message = e; // 'Talohtiön tietoja ei löytynyt.';
      // Laravel backendi palauttaa jsonissa errors avaimella virheet.
      if (e.response && e.response.data.errors) {
        error_message = Object.values(e.response.data.errors).join('<br/>');
      }
            
      yield put(get_referenced_user_actions.error(error_message))
    }

}

function* setCurrentUser(action) {

  try {
    /* infinite luuppi
    const isAuthenticated = yield select (getIsAuthenticated);
    if (!isAuthenticated)
      throw String("Kirjaudu ensin sisään."); 
*/
    const myUsers = yield select (getMyUsers);
    let theUser_id = parseInt(action.id)
    let currentUserId = yield select (getCurrentUserID);
    let theUser = theUser_id ? myUsers.find(myUser => myUser.id === theUser_id) : null;

    
    if (theUser) {

      yield put( set_current_user_actions.success(theUser) );


      // Useri vaihtui
      if (currentUserId != theUser_id) {
        yield put( current_user_changed_actions.yes() );
      }
    } else {
      
      throw String("Taloyhtiötä ei löydy.");
    }

  } catch (e) {
      console.log(e, action)
      yield put(set_current_user_actions.error(e));
  }
}

// Asettaa valitun talohtiön ja ohjaa sen etusivulle.
// Jos ei valittua taloyhtiötä, ohjataan taloyhtiön lisäykseen
function* setCurrentUserIfNotSet(action) {

  const currentUserID = yield select(getCurrentUserID);
  const myUsers = yield select (getMyUsers);

  if (!currentUserID || !myUsers.find(myUser => myUser.id === currentUserID)) {

  //  console.log("Setting current user if not set...");
    // Nulliksi asettamalla saadaan lopulta errori ja sitä kautta lisäämään uutta taloyhtiöö
    let id = typeof(myUsers[0]) !== 'undefined' ? myUsers[0].id : null;
  // console.log("Asetetaan käyttäjä id ", id)    ;
    yield put( set_current_user_actions.pending(id) );
 
  // Asetetaa uusiks, jos tuli jotain uutta freesiä inffoa.
  } else if (currentUserID) {
    yield put( set_current_user_actions.pending(currentUserID) );
  }
    

}



/**
 * Käyttäjän rekisteröityessä tai kirjautuessa kiinnitetään
 * mahdollisesti linkistä napattu taloyhtiö individuaalin usereihin.
 * Taloyhtiöillä on omat hash_id:t, jo saadaan sellainen statesta,
 * oletetaan että halutaan siihen liittyä.
 * 
 * @param  action 
 */
function* addToMyUsers(action) {
   
  try {

    // yield put( { type: actionTypes.ADD_TO_MY_USERS_ASYNC.PENDING } );
    yield put (add_to_my_users_actions.pending());
    let userReference = {
      id : '',
      hash_id : '',
      name : ''
    };
    if (action && action.payload)
      userReference.hash_id = action.payload.hash_id;
    else 
      userReference = yield select( getUserReference );

   // console.log("SAGA getDocuments, ", currentUserID);
   // Ei voi yield call(fetchDocuments(currentUserID))
   // console.log("AUTH SAGAAATSII ", userReferenceHashId);
   const myusers = yield call( addIndividualUser, { hash_id : userReference.hash_id } );
   // TODO: kun reksiteröityy ilman viitettä, lisää /oma sivulta käsin viitteen
   // > liittää kyl käyttäjiin mut jostain syystä ei tästä sit etene.
   // yield put( { type: actionTypes.ADD_TO_MY_USERS_ASYNC.SUCCESS, myusers : myusers } );
   yield put(add_to_my_users_actions.success(myusers));
 
 } catch (e) {
   console.log(e)
   // yield put( { type: actionTypes.ADD_TO_MY_USERS_ASYNC.ERROR } )
   yield put(add_to_my_users_actions.error(e));
 }
}



/**
 * Käyttäjän muuttaessa pois tai muuten vain irroitetaan
 * taloyhtiö individuaalin usereista. 
 * Taloyhtiöillä on omat hash_id:t, jo saadaan sellainen statesta,
 * oletetaan että halutaan siitä eroon (eli currentUserista)
 * 
 * @param  action 
 */
function* removeFromMyUsers(action) {
  try {

    yield put (remove_from_my_users_actions.pending());
   
   // Irroitetaan nykykäyttäjä!
   const currentUserID = yield select( getCurrentUserID );
   const myusers = yield call( removeIndividualUser, { id : currentUserID } );

   // Nykykäyttäjä pitää asettaa pois! kun se eijo enää oma 
   yield put( { type: actionTypes.UNSET_CURRENT_USER } );
   yield put( remove_from_my_users_actions.success(myusers) );
 
 } catch (e) {
   console.log(e)
   // yield put( { type: actionTypes.ADD_TO_MY_USERS_ASYNC.ERROR } )
   yield put(remove_from_my_users_actions.error(e));
 }
}

function* redirecToFront() {
  
  yield put( push('/') );
}

// Kun SET_CURRENT_USER.ERROR
// ohjataan johonki toiseen omaan taloyhtiöön, tai taloyhtiön lisäykseen.
function* redirectToAnyUser() {
  const myUsers = yield select (getMyUsers);

  // Eijo omia taloyhtiöitä, ohjataan taloyhtiön lisäykseen
  if (!myUsers.length || !myUsers[0].id)
    yield put ( push('/oma') );
  else
    yield put ( set_current_user_actions.pending(myUsers[0].id));
}

export default function* () {
  yield all([
    takeLatest(actionTypes.AUTH_SUCCESS, getMyInfo),
    // Tää tapahtuu, kun koitetaan laitteesta löytyvällä tokenilla toimiiko se vielä.
    takeLatest(actionTypes.LOAD_ACCESS_TOKEN_SUCCESS, getMyInfo ),
    takeLatest(actionTypes.REFRESH_ACCESS_TOKEN_ASYNC.SUCCESS, getMyInfo ),
    takeLatest(actionTypes.GET_ACCESS_TOKEN_WITH_EMAIL_TOKEN_SUCCESS, getMyInfo ),
    
    takeLatest(actionTypes.STORED_ACCESS_TOKEN_AUTH_ASYNC.SUCCESS, checkMyInfoLoaded),
    takeLatest(actionTypes.STORED_ACCESS_TOKEN_AUTH_ASYNC.ERROR, redirecToFront),
    
    
    takeLatest(actionTypes.MYINFO_EXISTS_ASYNC.ERROR, getMyInfo),
    //takeLatest(actionTypes.LOAD_MYINFO_ASYNC.PENDING, getMyInfo),
    takeLatest(actionTypes.GET_USER_REFERENCE_ASYNC.PENDING, getReferencedUser),
    takeLatest(actionTypes.LOAD_MYINFO_ASYNC.SUCCESS, setCurrentUserIfNotSet),
    // TODO, mitä pitäis tapahtuu, jos ei saaha omia tietoja haetuksi? testaa, paa pannu kii ja kaho mitä käy
    // Tuota noinnini
    takeLatest(actionTypes.LOAD_MYINFO_ASYNC.ERROR, setCurrentUserIfNotSet),

    takeLatest(actionTypes.SET_CURRENT_USER.PENDING, setCurrentUser),
    takeLatest(actionTypes.CURRENT_USER_CHANGED, redirecToFront),
    takeLatest(actionTypes.SET_CURRENT_USER.ERROR, redirectToAnyUser),


    takeLatest(actionTypes.ADD_USER_FORM_SENT_SUCCESS, addToMyUsers),
    takeLatest(actionTypes.ADD_TO_MY_USERS_ASYNC.SUCCESS, setCurrentUserIfNotSet),
    takeLatest(actionTypes.REMOVE_FROM_MY_USERS_CLICKED, removeFromMyUsers),
    takeLatest(actionTypes.REMOVE_FROM_MY_USERS_ASYNC.SUCCESS, setCurrentUserIfNotSet),
    takeLatest(actionTypes.REMOVE_FROM_MY_USERS_ASYNC.ERROR, getMyInfo),

    // Tuota, kun tunnistautuu esim hallituksen jäseneksi, pitäis current user saaha tsekattuu 
    takeLatest(actionTypes.SHAREHOLDER_AUTH_ASYNC.SUCCESS, getMyInfo),
    takeLatest(actionTypes.BOARD_MEMBER_AUTH_ASYNC.SUCCESS, getMyInfo)
  ])
}