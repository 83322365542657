// Ei käytössä 4.9.2019 - suora linkki valikosta https://www.isto.fi/Asiakasrekisterin_tietosuojaseloste.pdf
// Ota käyttöön jos tulee tänne oma.
import React, { Component } from 'react';


class Page_Privacy_Policy extends Component {

  render() {

    return (
        <div>
          <h1 className="page-title">Tietosuojaseloste</h1>

          <article className="card">
            Tietosuojaseloste löytyy osoitteesta <a href="https://www.isto.fi/tietosuojaseloste">Isto.fi</a>
          </article>


          </div>

    )
  }


}

export default Page_Privacy_Policy;
